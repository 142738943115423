import { createGlobalStyle } from "styled-components";
export const AndroidPassPreviewStyle = createGlobalStyle`
    .text-style {
        font-family: "Google Sans",Roboto,Arial,sans-serif !important;
    }
    .text-style.referral-text {
        font-family: Roboto,Arial,sans-serif !important;
    }
`;
export const SharedPassPreviewStyle = createGlobalStyle`
    .member-pass-container {
        overflow: auto;

        flex-grow: 1;
        flex-shrink: 1;

        height: 510px;
        max-height: 80vh;
        min-height: 200px;

        flex-basis: min(calc(50% - 10px), 100vw);
        max-width: 350px;
        min-width: 350px;

        border-radius: 10px;
        filter: drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.1))
            drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.06))
            drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.04));
    }
    .member-pass-container-modal-preview-android {
        max-height: 100%;
        margin-top: 20%;
        overflow: auto;
        overflow-x: hidden;
        flex: 1 1 min(calc(50% - 10px), 100vw);
        border-radius: 10px;
        filter:
        drop-shadow(0 11px 15px rgb(0 0 0 / 10%)) drop-shadow(0 9px 46px rgb(0 0 0 / 6%))
        drop-shadow(0 24px 38px rgb(0 0 0 / 4%));
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .member-pass-container-modal-preview-apple {
        max-height: 100%;
        margin-top: 26%;
        overflow: auto;
        overflow-x: hidden;
        flex: 1 1 min(calc(50% - 10px), 100vw);
        border-radius: 10px;
        filter:
        drop-shadow(0 11px 15px rgb(0 0 0 / 10%)) drop-shadow(0 9px 46px rgb(0 0 0 / 6%))
        drop-shadow(0 24px 38px rgb(0 0 0 / 4%));
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .field-text-apple {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-family: 'Khula', sans-serif;
    }
    .field-text-apple-sm {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        font-family: 'Khula', sans-serif;
    }
    .member-pass-mini-container {
        min-height: 65px;
        max-height: 65px;
        min-width: 80px;
        max-width: 80px;
        width: 100%;
        height: 100%;
        border-radius: 4px 4px 0 0;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        justify-content: flex-start;
        padding-bottom: 0.5rem;
    }
    .member-pass-container-mobile {
        overflow: auto;

        flex-grow: 1;
        flex-basis: 0;
        flex-shrink: 1;
        flex-direction: column;

        max-height: 80vh;

        max-width: 350px;
        width: min(100%, 100vw);
        padding-bottom: 10px;
        border-radius: 10px;
        // filter: drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.1))
        //     drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.06))
        //     drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.04));
    }
    .member-pass-container-back {
        background-color: white !important;
    }
    .member-pass-container-back-mobile {
        background-color: transparent !important;
        flex: 1 1 0;
    }
    .stripImageBox {
        width: 100%;
        height: 134.4px;
    }

    .stripImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .stripImageBoxGoogle {
        width: 100%;
        max-height: 110px;
    }

    .referral-box-zone {
        justify-content: center;
        display: flex;
        flex-grow: 1;
    }
    .ios-referral-qr-code-text {
        color: black;
        margin-bottom: -8px;
    }
    .referral-text {
        white-space: nowrap;
    }

    .balance-text {
        font-size: 10px;
        letting-spacing: 1px;
        font-weight: 600;
    }
    .balance-text-ios {
        text-transform: uppercase;
    }
    .balance-amount-text {
        font-size: 18px;
        font-weight: 400;
        line-height: .9;
    }

    .logo-text {
        font-size: 21.5px;
        font-weight: 600;
        line-height: 20px;
    }
    .mobile-size-logo-text{
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
    }
    .tier-name {
        font-weight: 400;
        font-size: 22px;
        line-height: 40px;
        word-break: break-word;
        font-family: 'Open Sans', sans-serif;
    }
    .text-align-center {
        text-align: center;
    }
    .program-name-text {
        font-size: 20px;
    }
    .android-label-text {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0;
        word-break: break-word;
        font-family: 'Open Sans', sans-serif;
    }
    .android-field-text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        word-break: break-word;
        font-family: 'Open Sans', sans-serif;
    }
    .header-text-apple {
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        padding-bottom: 0px !important;
        min-width: 103px;
    }
    .size-13 {
        font-size: 13px;
    }
    .size-15 {
        font-size: 15px;
    }
    {
        /* Utils */
    }
    .padding-1rem {
        padding: 1rem;
    }
    .bold-font {
        font-weight: 700;
    }
    .mobile-font-size-sm {
        font-size: 12px;
    }
    .mobile-font-size-lg {
        font-size: 13px;
    }
    .balance-amount-text-sm {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
    }
    .mobile-font-size-xs {
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
    }
    .pass-line {
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        max-width: 280px;
    }
    .mobile-font-size-sm {
        font-size: 12px;
    }
    .mobile-font-size-lg {
        font-size: 13px;
    }
    .size-13 {
        font-size: 13px;
    }
    .size-15 {
        font-size: 15px;
    }
    .text-align-center {
        text-align: center;
    } 
    .referral-qr-code-container {
        padding: 8px;
        background: white;
        border-radius: 4px;
        align-items: center;
    }
    .ios-referral-qr-code {
        width: initial !important;
        height: initial !important;
    }
`;
export const DynamicSharedPassPreviewStyle = createGlobalStyle<{
  textColor?: string;
  backgroundColor?: string;
}>`
    .member-pass-container, .member-pass-mini-container, .member-pass-container-modal-preview-apple, .member-pass-container-modal-preview-android {
        background-color: ${props => props.backgroundColor || "transparent"};
    }
    .member-pass-container, .member-pass-container-mobile {
        background-color: ${props => props.backgroundColor || "transparent"};
    }
    .text-style {
        color: ${props => props.textColor || "transparent"};
    }

    .pass-modal-image-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        filter: drop-shadow(rgba(255,255,255,.9) 0px 0px 5px) drop-shadow(rgba(0,0,0,0.1) 0px 3px 5px) drop-shadow(rgba(0,0,0,0.06) 0px 1px 18px) drop-shadow(rgba(0,0,0,0.08) 0px 6px 10px);
        z-index: 555;
        background-color: ${props => props.backgroundColor || "transparent"};
    }

    @media (max-width: 768px) { 
        .pass-modal-image-wrapper {
            filter: none;
        }
    }
`;