import React, { useMemo } from "react";
import QRCode from "react-qr-code";
import { FixedPxBox } from "@novel/shared/components/Box";
import { DynamicSharedPassPreviewStyle, SharedPassPreviewStyle } from "@novel/shared/components/PassPreview/sharedStyle";
import { StoreCreditCurrencyCode, formatMoney } from "@novel/shared/utils/formatMoney";
import { CurrencyCode } from "@novel/shared/__generated__/graphql";
import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { computeReferralString } from "@novel/shared/utils/computeReferralString";
import SvgPseudoQrCode from "@novel/shared/icons/PseudoQrCode";
import { FadeInOnLoadMedia } from "@novel/shared/components/FadeInOnLoadMedia";
import { isTooDarkCalculator } from "@novel/shared/utils/colorUtils";
export function AppleFrontPass(props: Partial<Omit<IRewardsTierCreate, "tierIndex">> & {
  readonly userDisplayName?: string;
  readonly showPseudoQr?: boolean;
  readonly orgName?: string;
  readonly currencyCode?: StoreCreditCurrencyCode;
  readonly userId?: string;
  readonly showBack?: boolean;
  readonly authLink?: string;
  readonly isLogin?: boolean;
  readonly modalPreview?: boolean;
  readonly mobile?: boolean;
}): JSX.Element {
  const {
    tierName,
    showPseudoQr,
    currencyCode = CurrencyCode.Usd,
    appleLogoImageUrlx2,
    appleLogoImageUrlx3,
    appleLogoImageUrl,
    stripImageUrl,
    stripImageUrlx2,
    stripImageUrlx3,
    textColor,
    backgroundColor,
    logoText,
    hasSpendReward,
    hasReferralReward,
    hasUpgradeTierReward,
    hasPerOrderReward,
    hasWelcomeReward,
    authLink,
    userDisplayName,
    balanceLabel,
    modalPreview,
    mobile,
    userId,
    welcomeBalanceFlatAmount
  } = props;
  const showQr = props.hasReferralReward || props.overrideQrLink;
  const qrLink = userId ? `${props.overrideQrLink || (authLink ? authLink : "www.novel.com")}?customer=${userId}` : props.overrideQrLink || (authLink ? authLink : "www.novel.com");
  return <React.Fragment>
            <FixedPxBox className={`${mobile ? "member-pass-container-mobile" : modalPreview ? "member-pass-container-modal-preview-apple" : "member-pass-container"}`} column justifyContent={"space-between"} style={{
      outline: props.isLogin && isTooDarkCalculator(backgroundColor || "#000") ? "1px solid #333" : "none"
    }}>
                <FixedPxBox column>
                    <FixedPxBox columnGap={1} py={1} px={mobile || modalPreview ? 1.25 : 1.75} alignItems="center">
                        <FadeInOnLoadMedia src={appleLogoImageUrlx3 || appleLogoImageUrlx2 || appleLogoImageUrl} height={"100%"} style={useMemo(() => ({
            objectFit: "contain",
            maxWidth: "160px",
            maxHeight: modalPreview ? "33px" : mobile ? "36.36px" : "50px"
          }), [mobile, modalPreview])} />
                        <FixedPxBox height={"100%"} flexGrow={1} alignItems={"center"} className="text-style logo-text">
                            {logoText}
                        </FixedPxBox>
                        {(hasSpendReward || hasReferralReward || hasPerOrderReward || hasUpgradeTierReward || hasWelcomeReward) && <FixedPxBox height="100%" alignItems="flex-end" column>
                                <FixedPxBox className="text-style balance-text balance-text-ios">
                                    {balanceLabel || " "}
                                </FixedPxBox>
                                <FixedPxBox alignItems="center" className={`text-style ${mobile || modalPreview ? "balance-amount-text-sm" : "balance-amount-text"}`}>
                                    {hasWelcomeReward ? formatMoney(welcomeBalanceFlatAmount?.[currencyCode] || 0, currencyCode) : formatMoney(0, currencyCode)}
                                </FixedPxBox>
                            </FixedPxBox>}
                    </FixedPxBox>
                    <React.Fragment>
                        <FixedPxBox className="stripImageBox" maxHeight={mobile ? 105.6 : modalPreview ? 107.52 : 134.4}>
                            <FadeInOnLoadMedia src={stripImageUrlx3 || stripImageUrlx2 || stripImageUrl} className="stripImage" height={"100%"} style={useMemo(() => ({
              maxHeight: `${mobile ? "125px" : "auto"}`,
              ...(!stripImageUrl ? {
                display: "none"
              } : {})
            }), [mobile, stripImageUrl])} />
                        </FixedPxBox>
                        <FixedPxBox px={mobile || modalPreview ? 0.25 : 0.75} justifyContent="space-between" style={useMemo(() => ({
            ...(!stripImageUrl ? {
              display: "none"
            } : {})
          }), [stripImageUrl])}>
                            <FixedPxBox column maxWidth="50%">
                                <FixedPxBox className="text-style padding-1rem header-text-apple">
                                    MEMBER
                                </FixedPxBox>
                                <div className={`text-style padding-1rem ${mobile || modalPreview ? "field-text-apple-sm" : "field-text-apple"}`} style={useMemo(() => ({
                paddingTop: modalPreview ? 5 : 0,
                display: "inline-block",
                width: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }), [modalPreview])}>
                                    {userDisplayName}
                                </div>
                            </FixedPxBox>
                            <FixedPxBox column>
                                <FixedPxBox className="text-style padding-1rem header-text-apple">
                                    TIER
                                </FixedPxBox>
                                <FixedPxBox className={`text-style padding-1rem ${mobile || modalPreview ? "field-text-apple-sm" : "field-text-apple"}`} style={useMemo(() => ({
                paddingTop: modalPreview ? 5 : 0
              }), [modalPreview])}>
                                    {tierName}
                                </FixedPxBox>
                            </FixedPxBox>
                        </FixedPxBox>
                    </React.Fragment>
                </FixedPxBox>
                {useMemo(() => showQr ? <div className="referral-box-zone" style={{
        height: "auto",
        alignItems: "flex-end"
      }}>
                                <FixedPxBox justifyContent={"center"} column flexGrow={1} alignItems={"center"} style={{
          marginBottom: `${mobile ? "0rem" : "1rem"}`
        }}>
                                    <FixedPxBox className="referral-qr-code-container" column>
                                        {showPseudoQr ? <FixedPxBox {...mobile || modalPreview ? {
              height: 80,
              width: 80,
              minHeight: 80,
              minWidth: 80
            } : {
              height: 120,
              width: 120,
              minHeight: 120,
              minWidth: 120
            }}>
                                                <SvgPseudoQrCode />
                                            </FixedPxBox> : <QRCode className="ios-referral-qr-code referral-qr-code" value={qrLink} size={mobile || modalPreview ? 80 : 120} style={modalPreview ? {} : {
              height: "auto",
              maxWidth: "100%",
              width: "100%"
            }} viewBox={`0 0 125 125`} />}
                                        <div className="ios-referral-qr-code-text text-align-center mobile-font-size-xs referral-text">
                                            {props.overrideQrText || computeReferralString({
                hasReferralReward: props.hasReferralReward,
                refereeRewardFlatAmount: props.refereeRewardFlatAmount,
                perReferralRewardFlatAmount: props.perReferralRewardFlatAmount
              }, currencyCode)}
                                        </div>
                                    </FixedPxBox>
                                </FixedPxBox>
                            </div> : <div style={{
        height: `${mobile ? "143.08px" : "auto"}`
      }} />, [showQr, qrLink, mobile, showPseudoQr, modalPreview, props.overrideQrText, props.refereeRewardFlatAmount, props.perReferralRewardFlatAmount, props.hasReferralReward, currencyCode])}
            </FixedPxBox>
            <SharedPassPreviewStyle />
            <DynamicSharedPassPreviewStyle backgroundColor={backgroundColor} textColor={textColor} />
        </React.Fragment>;
}