import { isSrcCached } from "@novel/shared/utils/isSrcCached";
import cn from "classnames";
import React, { ReactEventHandler, useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
export const WIDTH_SIZES = {
  extraSmall: 20,
  small: 40,
  applePushIcon: 44,
  androidPushIcon: 44,
  biggerSmall: 48,
  mediumSmaller: 60,
  mediumSmall: 80,
  medium: 93,
  large: 100,
  larger: 160,
  largest: 360
};
const BORDER_RADIUS_SIZES: typeof WIDTH_SIZES = {
  extraSmall: 4,
  small: 4,
  applePushIcon: 12,
  androidPushIcon: 6,
  biggerSmall: 4,
  mediumSmaller: 4,
  mediumSmall: 4,
  medium: 4,
  large: 4,
  larger: 6,
  largest: 10
};
const BOX_SHADOWS: { [key in keyof typeof WIDTH_SIZES]: string } = {
  extraSmall: "1px 1px 2.5px rgba(0, 0, 0, 0.3)",
  small: "1px 1px 2.5px rgba(0, 0, 0, 0.3)",
  applePushIcon: "1px 1px 2.5px rgba(0, 0, 0, 0.3)",
  androidPushIcon: "1px 1px 2.5px rgba(0, 0, 0, 0.3)",
  biggerSmall: "2px 2px 4px rgba(0, 0, 0, 0.3)",
  mediumSmaller: "2px 2px 4px rgba(0, 0, 0, 0.3)",
  mediumSmall: "2px 2px 4px rgba(0, 0, 0, 0.3)",
  medium: "2px 2px 4px rgba(0, 0, 0, 0.3)",
  large: "3px 3px 6px rgba(0, 0, 0, 0.3)",
  larger: "3px 3px 6px rgba(0, 0, 0, 0.3)",
  largest: "4px 4px 10px rgba(0, 0, 0, 0.3)"
};
const StyledMediaWrapper = styled.div<{
  size: Props["size"];
  hasBorderRadius: boolean;
}>`
    ${props => props.hasBorderRadius ? `border-radius: ${BORDER_RADIUS_SIZES[props.size || "medium"]}px;` : ""}
`;
const StyledMediaContainer = styled.div<{
  size: Props["size"];
  hasBorderRadius: boolean;
  containerBackgroundColor?: string;
  hasBoxShadow: boolean;
  onClick?(e: React.SyntheticEvent<HTMLDivElement, Event>): void;
}>`
    align-items: center;
    background-color: ${props => props.containerBackgroundColor || "#fff"};
    display: flex;
    height: ${props => WIDTH_SIZES[props.size || "medium"]}px;
    justify-content: center;
    min-height: ${props => WIDTH_SIZES[props.size || "medium"]}px;
    min-width: ${props => WIDTH_SIZES[props.size || "medium"]}px;
    overflow: hidden;
    position: relative;
    width: ${props => WIDTH_SIZES[props.size || "medium"]}px;
    ${props => props.hasBorderRadius ? `border-radius: ${BORDER_RADIUS_SIZES[props.size || "medium"]}px;` : ""}
    ${props => props.hasBoxShadow ? `box-shadow: ${BOX_SHADOWS[props.size || "medium"]};` : ""}
    ${props => props.onClick ? `cursor: pointer;` : ""}

  &.error {
        position: relative;
        &:after {
            ${props => props.hasBorderRadius ? `border-radius: ${BORDER_RADIUS_SIZES[props.size || "medium"]}px;` : ""}
            background: #fff;
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
        }
    }
`;
type LongerDimension = "vertical" | "horizontal";
const StyledMedia = styled.img.attrs<{
  direction: LongerDimension;
}>(props => ({
  ...props,
  className: cn("fixed-dimension-image", `fixed-dimension-image-${props.direction}`)
}))<{
  size: Props["size"];
  isLoaded: boolean;
  direction: LongerDimension;
}>`
    transition: opacity 0.15s ease-in-out;
    ${props => !props.isLoaded ? `
    visibility: hidden;
    opacity: 0;
  ` : `
    opacity: 1;
  `}
    ${props => props.direction === "horizontal" ? `
        min-height: initial;
        min-width: ${WIDTH_SIZES[props.size || "medium"]}px;
        max-width: ${WIDTH_SIZES[props.size || "medium"]}px;
      ` : `
        min-height: ${WIDTH_SIZES[props.size || "medium"]}px;
        max-height: ${WIDTH_SIZES[props.size || "medium"]}px;
        min-width: initial;
      `}
`;

// TODO: make this better
const FallbackImage = styled.div.attrs(props => ({
  ...props,
  className: "fixed-dimension-fallback-image"
}))<{
  size: Props["size"];
  hasBorderRadius: boolean;
}>`
    width: ${props => WIDTH_SIZES[props.size || "medium"]}px;
    height: ${props => WIDTH_SIZES[props.size || "medium"]}px;
    ${props => props.hasBorderRadius ? `border-radius: ${BORDER_RADIUS_SIZES[props.size || "medium"]}px;` : ""}
`;
interface Props {
  readonly className?: string;
  readonly calculation?: "cover" | "contain";
  readonly src?: string;
  readonly alt?: string;
  readonly hasBorderRadius?: boolean;
  readonly hasBoxShadow?: boolean;
  onClick?(e: React.SyntheticEvent<HTMLDivElement, Event>): void;
  readonly size?: keyof typeof WIDTH_SIZES;
  readonly type?: "image" | "video";
  // video only
  readonly poster?: string;
  readonly controls?: boolean;
  readonly muted?: boolean;
  readonly loop?: boolean;
  readonly autoPlay?: boolean;
  readonly title?: string;
  readonly containerBackgroundColor?: string;
}
export function FixedDimensionMedia({
  className,
  calculation = "contain",
  src,
  alt,
  hasBoxShadow = true,
  hasBorderRadius = true,
  size = "medium",
  type = "image",
  poster,
  controls,
  muted,
  loop,
  autoPlay,
  title,
  containerBackgroundColor,
  ...other
}: Props): JSX.Element {
  const [imageHadError, setImageHadError] = useState(false);
  const [isLoaded, setIsLoadedState] = useState(!!src && isSrcCached(src));
  const [direction, setDirection] = useState<LongerDimension>("horizontal");
  const [overrideAsContain, setOverrideAsContain] = useState<boolean>(false);
  const elementRef = useRef<HTMLElement>();
  const setLoadedCb: ReactEventHandler<HTMLImageElement> = useCallback(e => {
    const containingElement = elementRef?.current?.parentElement;
    let containerHeightToWidth = 1;
    if (containingElement) {
      // client height excludes borders (vs. offsetHeight)
      // we want the inner dimensions so this is correct
      containerHeightToWidth = containingElement.clientHeight / containingElement.clientWidth;
    }
    const heightToWidth = e.currentTarget.naturalHeight / e.currentTarget.naturalWidth;
    if (heightToWidth > containerHeightToWidth) {
      setDirection("vertical");
    }
    // if ratio is too far off then cover looks strange
    if (heightToWidth > containerHeightToWidth * 2 || heightToWidth < containerHeightToWidth / 2) {
      setOverrideAsContain(true);
    }
    setIsLoadedState(true);
  }, []);
  const setHadErrorCb = useCallback(() => {
    setImageHadError(true);
  }, []);
  const calculationIsContain = calculation === "contain" || overrideAsContain;
  const finalDirection = calculationIsContain && direction === "horizontal" || !calculationIsContain && direction === "vertical" ? "horizontal" : "vertical";
  const dimensionsStyleObj = useMemo(() => {
    return finalDirection === "horizontal" ? {
      minWidth: WIDTH_SIZES[size || "medium"],
      width: WIDTH_SIZES[size || "medium"]
    } : {
      minHeight: WIDTH_SIZES[size || "medium"],
      height: WIDTH_SIZES[size || "medium"]
    };
  }, [finalDirection, size]);
  const renderedMedia = useMemo(() => {
    if (src) {
      if (type === "image") {
        return <StyledMedia src={src} size={size} direction={finalDirection} alt={alt} isLoaded={typeof window === "undefined" || isLoaded} // handling SSR
        onLoad={setLoadedCb} onError={setHadErrorCb} {...dimensionsStyleObj} title={title} />;
      }
      if (type === "video") {
        return <video controls={controls} muted={muted} loop={loop} autoPlay={autoPlay} poster={poster} onError={setHadErrorCb}>
                        <source src={src} type="video/webm" />
                        <source src={src} type="video/mp4" />
                    </video>;
      }
    } else {
      return <FallbackImage hasBorderRadius={hasBorderRadius} size={size} />;
    }
  }, [alt, autoPlay, controls, dimensionsStyleObj, finalDirection, hasBorderRadius, isLoaded, loop, muted, poster, setHadErrorCb, setLoadedCb, size, src, type, title]);
  return <StyledMediaWrapper ref={(elementRef as any)} size={size} hasBorderRadius={hasBorderRadius} className={cn("media-wrapper", className, {
    error: imageHadError,
    size
  })}>
            <StyledMediaContainer size={size} hasBorderRadius={hasBorderRadius} hasBoxShadow={hasBoxShadow} containerBackgroundColor={containerBackgroundColor} className={cn("media-container", className, size, {
      error: imageHadError
    })} {...other}>
                {renderedMedia}
            </StyledMediaContainer>
        </StyledMediaWrapper>;
}