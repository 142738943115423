import React, { useMemo } from "react";
import QRCode from "react-qr-code";
import { FixedPxBox } from "@novel/shared/components/Box";
import { AndroidPassPreviewStyle, DynamicSharedPassPreviewStyle, SharedPassPreviewStyle } from "@novel/shared/components/PassPreview/sharedStyle";
import { StoreCreditCurrencyCode, formatMoney } from "@novel/shared/utils/formatMoney";
import { CurrencyCode } from "@novel/shared/__generated__/graphql";
import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { invertedBrightnessColor, isTooDarkCalculator } from "@novel/shared/utils/colorUtils";
import { computeReferralString } from "@novel/shared/utils/computeReferralString";
import SvgPseudoQrCode from "@novel/shared/icons/PseudoQrCode";
import { FadeInOnLoadMedia } from "@novel/shared/components/FadeInOnLoadMedia";
export function AndroidFrontPass(props: Partial<Omit<IRewardsTierCreate, "tierIndex">> & {
  readonly userDisplayName?: string;
  readonly showPseudoQr?: boolean;
  readonly orgName?: string;
  readonly userId?: string;
  readonly currencyCode?: StoreCreditCurrencyCode;
  readonly showBack?: boolean;
  readonly authLink?: string;
  readonly isLogin?: boolean;
  readonly modalPreview?: boolean;
  readonly mobile?: boolean;
  readonly passOrgNameOverride?: string;
}): JSX.Element {
  const {
    showPseudoQr,
    currencyCode = CurrencyCode.Usd,
    googleLogoImageUrl,
    stripImageUrl,
    stripImageUrlx2,
    stripImageUrlx3,
    passOrgNameOverride,
    googleStripImageUrl,
    backgroundColor,
    textColor,
    orgName,
    tierName,
    hasSpendReward,
    hasReferralReward,
    hasUpgradeTierReward,
    hasPerOrderReward,
    hasWelcomeReward,
    authLink,
    modalPreview,
    mobile,
    userDisplayName,
    balanceLabel,
    welcomeBalanceFlatAmount,
    userId
  } = props;
  const showQr = props.hasReferralReward || props.overrideQrLink;
  const qrLink = userId ? `${props.overrideQrLink || (authLink ? authLink : "www.novel.com")}?customer=${userId}` : props.overrideQrLink || (authLink ? authLink : "www.novel.com");
  return <React.Fragment>
            <FixedPxBox className={`${mobile ? "member-pass-container-mobile" : modalPreview ? "member-pass-container-modal-preview-android" : "member-pass-container"}`} column justifyContent={"space-between"} style={{
      padding: "0px",
      outline: props.isLogin && isTooDarkCalculator(backgroundColor || "#000") ? "1px solid #333" : "none"
    }}>
                <FixedPxBox column>
                    <FixedPxBox columnGap={1} alignItems="center" style={useMemo(() => ({
          padding: `${modalPreview ? "1rem" : "16px"}`,
          borderBottom: "1px solid #959595"
        }), [modalPreview])}>
                        <FadeInOnLoadMedia src={googleLogoImageUrl} height={"100%"} style={useMemo(() => ({
            objectFit: "contain",
            maxWidth: "50%",
            width: "33.5px",
            height: "33.5px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            border: "1.5px solid transparent"
          }), [])} />
                        <FixedPxBox height={"100%"} flexGrow={1} alignItems={"center"} className="text-style mobile-size-logo-text">
                            {passOrgNameOverride || orgName || "Your Loyalty Pass"}
                        </FixedPxBox>
                    </FixedPxBox>
                    <FixedPxBox className="text-style padding-1rem tier-name" style={{
          paddingBottom: `${mobile || modalPreview ? "0px" : "1rem"}`,
          paddingTop: `${mobile || modalPreview ? "0rem" : "1rem"}`
        }}>
                        {tierName === "Base Tier" && orgName ? `Your ${passOrgNameOverride || orgName} Pass` : tierName}
                    </FixedPxBox>
                    <FixedPxBox className="padding-1rem text-style" style={modalPreview ? {
          // ensures no scroll overflow in desktop flow
          paddingTop: ".2rem"
        } : {}} justifyContent="space-between">
                        {userDisplayName && <FixedPxBox column>
                                <div className="text-style android-label-text" style={{
              fontSize: `${modalPreview ? "10x" : "14px"}`
            }}>
                                    Member
                                </div>
                                <div className="text-style android-field-text" style={{
              fontSize: `${modalPreview ? "12px" : "16px"}`
            }}>
                                    {userDisplayName}
                                </div>
                            </FixedPxBox>}
                        {(hasSpendReward || hasReferralReward || hasUpgradeTierReward || hasPerOrderReward || hasWelcomeReward) && <FixedPxBox column>
                                <div className="text-style android-label-text" style={{
              fontSize: `${modalPreview ? "10x" : "14px"}`
            }}>
                                    {balanceLabel || " "}
                                </div>
                                <div className="text-style android-field-text" style={{
              fontSize: `${modalPreview ? "12px" : "16px"}`
            }}>
                                    {/* balance logic */}
                                    {hasWelcomeReward ? formatMoney(welcomeBalanceFlatAmount?.[currencyCode] || 0, currencyCode) : formatMoney(0, currencyCode)}
                                </div>
                            </FixedPxBox>}
                    </FixedPxBox>
                </FixedPxBox>
                <FixedPxBox column>
                    {useMemo(() => showQr ? <div className="referral-box-zone" style={{
          height: `${mobile ? "137.66px" : "auto"}`,
          alignItems: `${mobile ? "flex-end" : "center"}`
        }}>
                                    <FixedPxBox justifyContent={"center"} column flexGrow={1} alignItems={"center"} style={{
            marginBottom: `${mobile ? "0rem" : "1rem"}`
          }}>
                                        <FixedPxBox className="referral-qr-code-container" flexGrow={1} width={mobile ? 100 : modalPreview ? 91 : 137.5}>
                                            {showPseudoQr ? <SvgPseudoQrCode /> : <QRCode className="referral-qr-code" value={props.overrideQrLink ? `${props.overrideQrLink}?customer=${userId}` : qrLink} size={mobile ? 100 : modalPreview ? 91 : 137.5} style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%"
              }} viewBox={`0 0 125 125`} />}
                                        </FixedPxBox>
                                        <div className="text-style text-align-center mobile-font-size-xs referral-text">
                                            {props.overrideQrText || computeReferralString({
                hasReferralReward: props.hasReferralReward,
                refereeRewardFlatAmount: props.refereeRewardFlatAmount,
                perReferralRewardFlatAmount: props.perReferralRewardFlatAmount
              }, currencyCode)}
                                        </div>
                                    </FixedPxBox>
                                </div> : <div style={{
          height: `${mobile ? "216.33px" : "auto"}`
        }} />, [showQr, currencyCode, mobile, modalPreview, props.overrideQrLink, props.overrideQrText, props.perReferralRewardFlatAmount, props.refereeRewardFlatAmount, props.hasReferralReward, qrLink, showPseudoQr, userId])}
                    <div className="stripImageBoxGoogle">
                        <FadeInOnLoadMedia src={googleStripImageUrl || stripImageUrlx3 || stripImageUrlx2 || stripImageUrl} className="stripImage" height={"100%"} style={useMemo(() => ({
            maxHeight: `${mobile ? "110px" : "auto"}`
          }), [mobile])} />
                    </div>
                </FixedPxBox>
            </FixedPxBox>
            {/* override text color for Android as Google auto-picks from background */}
            <style global jsx>{`
                .text-style {
                    color: ${invertedBrightnessColor(backgroundColor || "#000")} !important;
                }
            `}</style>
            <AndroidPassPreviewStyle />
            <SharedPassPreviewStyle />
            <DynamicSharedPassPreviewStyle backgroundColor={backgroundColor} textColor={textColor} />
        </React.Fragment>;
}