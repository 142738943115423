import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { BigIntStr } from "@novel/shared/interfaces/shared/BigIntStr";
import { StoreCreditCurrencyCode } from "@novel/shared/utils/formatMoney";

export function claimBalance(
    rewardTier: Partial<IRewardsTierCreate>,
    resolvedCurrency: StoreCreditCurrencyCode,
    referrerRewardTier?: Partial<IRewardsTierCreate>,
): BigIntStr {
    const welcomeBonus =
        rewardTier?.welcomeBalanceFlatAmount?.[resolvedCurrency] || BigIntStr.toBigIntStr(0);
    const refereeBonus =
        referrerRewardTier?.refereeRewardFlatAmount?.[resolvedCurrency] || BigIntStr.toBigIntStr(0);
    return BigIntStr.max([welcomeBonus, refereeBonus]);
}
